<template>
  <div class="order-details-container">
    <!-- 兑换订单 -->
    <div v-if="$route.query.type = 'exchange'">
      <div
        class="order-details-type row-center"
        style="height: 8rem;flex-direction: column;font-size:16px"
      >
        <div
          style="display: flex;justify-content: center;align-items: center;"
          v-if="$route.query.type = 'exchange'"
        >
          <img
            style="herght:auto"
            :class="orderInfo.status==1 ? 'shipped-img' : ''"
            :src="
                orderInfo.status==0
                  ? require('../assets/icon/order_error.png')
                  : orderInfo.status==1
                  ? require('../assets/icon/shipped.png')
                  : require('../assets/icon/logistics.png')
              "
          />
          {{
              orderInfo.status==0
                ? "已取消"
                : orderInfo.status==1
                ? "待发货"
                : orderInfo.status==2
                ?"待收货"
                :"已完成"
            }}
        </div>

        <div
          v-if="orderInfo.status==1"
          class="cancel-button row-center"
          @click="cancerOrder"
        >取消订单</div>
        <div
          v-if="orderInfo.status==2||orderInfo.status==3"
          :class="orderInfo.exchange == 3 ? 'logistics-button' : ((orderInfo.exchange != 2) && !orderInfo.kdNo) ? 'go-disable-pay' : 'logistics-button'"
          class="row-center"
          @click="veiwOrder"
        >{{ orderInfo.exchange == 2 ? '查看卡密' : orderInfo.exchange == 3 ? '查看' : '查看物流' }}</div>

      </div>
      <div
        v-if="orderInfo.exchange != 2 && orderInfo.exchange != 3"
        class="composite"
      >
        <div class="title">收货地址</div>
        <div class="list">
          <p class="list-detail-name">{{ orderInfo.addressName }}</p>
          <p
            class="list-detail-name"
            style="margin-left:21px"
          >{{ orderInfo.addressPhone }}</p>
        </div>
        <div
          class="list"
          style="margin-top:11px"
        >
          <p class="list-detail-name">
            {{ orderInfo.addressDetail }}
          </p>
        </div>

      </div>
      <div
        style="padding: 22px 0.875rem 14px 0.875rem;"
        class="order-detail-img-name-price row-start"
      >
        <div
          class="order-detail-img-name-price-img"
          :style="'width: 80px;height: 80px;margin:18px 18px 18px 0px;background-color: transparent;border-radius: 4px;'"
        >
          <div class="order-detail-img-name-price-img-inline">
            <img
              style="border-radius: 4px"
              :src="orderInfo.image"
              alt=""
            />
          </div>
        </div>
        <div>
          <p class="order-detail-name">{{ orderInfo.name }}</p>
          <p class="order-detail-name">ID:{{ orderInfo.showId && orderInfo.showId != '0'  ? orderInfo.showId : orderInfo.uniqueCode}}</p>
        </div>
      </div>
      <div
        v-if="$route.query.type == 'exchange'"
        style="padding-top: 22px"
        class="order-detail-content"
      >
        <!-- 订单编号 -->
        <div class="row-between">
          <p class="order-detail-item-title">订单编号</p>
          <p class="order-detail-item-content">
            {{ orderInfo.orderId }}
            <a
              class="orderNo-copy"
              :data-clipboard-text="orderInfo.orderId"
              @click="copy"
            >复制</a>
          </p>
        </div>
        <!-- 创建时间 -->
        <div
          v-if="$route.query.type == 'exchange'"
          class="row-between"
        >
          <p class="order-detail-item-title">创建时间</p>
          <p class="order-detail-item-content">{{ orderInfo.createTime }}</p>
        </div>
        <!-- 发货时间 -->
        <div
          v-if="$route.query.type == 'exchange'&& orderInfo.exchange != 2 && orderInfo.exchange != 3 &&(orderInfo.status==2||orderInfo.status==3)"
          class="row-between"
        >
          <p class="order-detail-item-title">发货时间</p>
          <p class="order-detail-item-content">{{ orderInfo.deliverGoodsTime }}</p>
        </div>
        <!-- 完成时间 -->
        <div
          v-if="$route.query.type == 'exchange'&&orderInfo.status==3"
          class="row-between"
        >
          <p class="order-detail-item-title">完成时间</p>
          <p class="order-detail-item-content">{{ orderInfo.exchange == 2 || orderInfo.exchange == 3 ? orderInfo.createTime : orderInfo.confirmTime }}</p>
        </div>
        <!-- 取消时间 -->
        <div
          v-if="$route.query.type == 'exchange'&&orderInfo.status==0"
          class="row-between"
        >
          <p class="order-detail-item-title">取消时间</p>
          <p class="order-detail-item-content">{{ orderInfo.updateTime }}</p>
        </div>
        <!-- 数量 -->
        <div class="row-between">
          <p class="order-detail-item-title">数量</p>
          <p class="order-detail-item-content">
            {{
             orderInfo.amount
            }}
          </p>
        </div>
      </div>
    </div>
    <el-dialog
      title="确定要取消订单吗？"
      :visible.sync="cancerVisible"
      @confirm="donationCancerOrder()"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top: 2rem"
        class="dialog-footer row-center"
      >
        <el-button @click="cancerVisible = false">取 消</el-button>
        <div
          @click="donationCancerOrder()"
          class="confirm-button row-center"
        >
          确定
        </div>
      </span>
    </el-dialog>
    <!-- 复制卡密 -->
    <el-dialog
      :visible.sync="cardOpenVisible"
      @confirm="cardOpenVisible = false"
      class="donation-dialog"
      center
    >
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="card-title"
      >{{cardInfo.productName}}</p>
      <div
        style="width: 90% !important;margin-left: 5%;"
        class="card-tip"
      >
        <p>卡号：</p>
        <p @click="cardNoCopy">{{ cardInfo.cardNo }}</p>
        <i
          style="margin-left:10px;"
          :data-clipboard-text="cardInfo.cardNo"
          @click="cardNoCopy"
          class="cardNo-copy el-icon-document-copy"
        ></i>
      </div>
      <div
        style="width: 90% !important;margin-left: 5%;"
        class="card-tip"
      >
        <p>卡密：</p>
        <p @click="cardNoCopy">{{ cardInfo.cardPwd }}</p>
        <i
          style="margin-left:10px;"
          :data-clipboard-text="cardInfo.cardPwd"
          @click="cardPwdCopy"
          class="cardPwd-copy el-icon-document-copy"
        ></i>
      </div>

    </el-dialog>
    <!--查看优惠券 -->
    <el-dialog
      :visible.sync="couponOpenVisible"
      @confirm="couponOpenVisible = false"
      class="coupon-dialog"
      center
    >
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="coupon-title"
      >{{couponInfo.couponTitle}}</p>
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="coupon-use-price"
      >满{{couponInfo.useMinPrice}}减{{couponInfo.couponPrice}}元</p>
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="coupon-count"
      >x1</p>
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="coupon-tip"
      >已存入账户中</p>
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="coupon-tip"
      >请在“我的”-“优惠券”中查看</p>

    </el-dialog>
  </div>
</template>
<script>
import api from "../api/index-client";
import Clipboard from "clipboard";
import Vue from "vue";
import config from "../config";
import { mapGetters } from "vuex";
import { CountDown,Popup,Button } from "vant";
import { Dialog,Radio,Input,RadioGroup } from "element-ui";
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Input);
Vue.use(Dialog);
Vue.use(CountDown);
Vue.use(Button);
Vue.use(Popup);
export default {
  data () {
    return {
      couponOpenVisible: false,
      cardOpenVisible: false,
      couponInfo: {
        couponTitle: '优惠券名称优惠券名称优惠券名称优惠券名称优惠券名称',
        useMinPrice: 10,
        couponPrice: 1,
        count: 10,
      },
      cardInfo: {},
      donationCollectionShowList: [],
      donationCollectionList: [],
      donationCollectionListshow: false,
      browerOpenVisible: false,
      lefttime: 300000,
      cancerVisible: false,
      cancerReasonVisible: false,
      cancerReasonText: "",
      cancerReason: "",
      orderInfo: {

      },
      finishStatus: false,
      onChainStatus: false,
      cancerStatus: false,
      donationStatus: false,
      waitForPay: false,
      timeLimit: null,
      autoStart: false,
      isPicoShowPay: false,
      payType: "weixin",
      mediaType: config.mediaType,
      materialListShow: [1],
      materialListCache: [1,2],
      materialHide: false,
    };
  },
  computed: {
    ...mapGetters(["userOpenId"]),
  },
  created () {
    this.$store.commit("SHOW_APPLOADING");
    this.getOrderDetailsInfo();
  },
  methods: {
    veiwOrder () {
      if (this.orderInfo.exchange == 2) {
        this.veiwCard()
      } else if (this.orderInfo.exchange == 3) {
        this.veiwConpon()
      } else {
        if (this.orderInfo.kdNo) {
          this.$router.push('/logistics?orderId=' + this.$route.query.orderId + '&type=exchange')
        }
      }

    },
    // 查看优惠券详情
    veiwConpon (e) {
      api
        .get('coupons/detail?couponId=' + this.orderInfo.userCouponId)
        .then(result => {
          if (result.data.success) {
            this.couponInfo = result.data.data
            this.couponOpenVisible = true
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })

    },
    // 查看卡密
    veiwCard (e) {
      api
        .get('assets/exchange/getCardInfo/' + this.$route.query.orderId)
        .then(result => {
          if (result.data.success) {
            this.cardInfo = result.data.data
            this.cardOpenVisible = true
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 取消订单逻辑
    cancerOrder () {
      this.cancerVisible = true
    },
    // 卡号复制
    cardNoCopy () {
      this.cardOpenVisible = false
      var clipboard = new Clipboard(".cardNo-copy");
      clipboard.on("success",(e) => {
        this.$toast('复制成功');
        //  释放内存
        clipboard.destroy();
      });
      clipboard.on("error",(e) => {
        // 不支持复制
        this.$toast('复制失败');
        // 释放内存
        clipboard.destroy();
      });
    },
    // 卡密复制
    cardPwdCopy () {
      this.cardOpenVisible = false
      var clipboard = new Clipboard(".cardPwd-copy");
      clipboard.on("success",(e) => {
        this.$toast('复制成功');
        //  释放内存
        clipboard.destroy();
      });
      clipboard.on("error",(e) => {
        // 不支持复制
        this.$toast('复制失败');
        // 释放内存
        clipboard.destroy();
      });
    },
    // 转赠订单取消（无原因）
    donationCancerOrder () {
      api
        .get('assets/exchange/cancel?orderId=' + this.$route.query.orderId)
        .then(result => {
          if (result.data.success) {
            this.$toast.success('成功')
            this.cancerVisible = false
            this.getOrderDetailsInfo()
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 获取订单详情信息
    getOrderDetailsInfo () {
      // 兑换订单详情
      api
        .post('assets/exchange/order/' + this.$route.query.orderId)
        .then(result => {
          this.$store.commit("HIDE_APPLOADING");
          if (result.data.success) {
            this.orderInfo = result.data.data
            localStorage.setItem("orderIndex",result.data.data.status);
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }

        });
    },
    // 复制订单号
    copy () {
      var clipboard = new Clipboard(".orderNo-copy");
      clipboard.on("success",(e) => {
        this.$toast.success("复制成功");
        //  释放内存
        clipboard.destroy();
      });
      clipboard.on("error",(e) => {
        // 不支持复制
        this.$toast.fail("复制失败");
        // 释放内存
        clipboard.destroy();
      });
    },
  },

}
</script>
<style lang="less" scoped>
.order-details-container {
  margin-top: 3.875rem;
  height: calc(100vh - 3.875rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;
  .go-disable-pay {
    width: 6.8125rem;
    height: 1.875rem;
    border-radius: 0.9375rem;
    background-image: url('../assets/icon/sold-out.png');
    background-attachment: scroll;
    background-position: 40% 0%;
    background-size: 200% auto;
    background-color: transparent;
    margin-top: 0.75rem;
    font-size: 14px;
    font-family: lantingheiweight;
    font-weight: 400;
    color: #ffffff;
    line-height: 19px;
  }
  .coupon-title {
    font-size: 18px;
    width: 100%;
    text-align: center;
    font-family: lantingheiweight;
    font-weight: 800;
    color: rgb(204, 204, 204);
    line-height: 1.5625rem;
    margin-bottom: 20px;
  }
  .coupon-use-price {
    font-size: 16px;
    width: 100%;
    text-align: center;
    font-family: lantingheiweight;
    font-weight: 500;
    color: rgb(204, 204, 204);
    line-height: 1.5625rem;
  }
  .coupon-count {
    font-size: 16px;
    width: 100%;
    text-align: center;
    font-weight: 300;
    color: rgb(204, 204, 204);
    line-height: 1.5625rem;
    margin-bottom: 20px;
  }
  .coupon-tip {
    font-size: 14px;
    width: 100%;
    text-align: center;
    font-weight: 400;
    color: rgb(204, 204, 204);
    line-height: 1.5625rem;
  }
  .card-title {
    font-size: 18px;
    width: 100%;
    text-align: center;
    font-family: lantingheiweight;
    font-weight: 600;
    color: rgb(204, 204, 204);
    line-height: 1.5625rem;
    margin-bottom: 20px;
  }
  .card-tip {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    width: 100%;
    text-align: center;
    font-family: lantingheiweight;
    font-weight: 500;
    color: rgb(214, 214, 214);
    line-height: 1.5625rem;
    margin-bottom: 10px;
  }
  .order-detail-img-name-price-img {
    display: flex;
    justify-content: center;
    align-items: center;
    &-inline {
      display: block;
      text-align: center;
      width: 100%;
      height: 100%;
      position: relative;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        bottom: 0;
        right: 0;
        transform: translate(-50%, -50%);
      }
    }
  }
  .buy-now {
    width: 90%;
    height: 5rem;
    background: #ffffff;
    border-radius: 1rem;
    z-index: 1000;
    padding: 1.5625rem 0 1.75rem 0;

    .order-detail-prise-buy {
      width: 100%;
    }
  }

  .mask {
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    /*宽度设置为100%，这样才能使隐藏背景层覆盖原页面*/
    height: 100%;
    filter: alpha(opacity=60);
    /*设置透明度为60%*/
    opacity: 0.6;
    /*非IE浏览器下设置透明度为60%*/
    z-index: 999;
  }
  .cancer-order-title {
    font-size: 20px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #d8d8d8;
    line-height: 20px;
    margin-top: 1.75rem;
  }
  .cancer-submit {
    width: 90%;
    height: 2.875rem;
    background-image: url('../assets/icon/confirm-button.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: 100% auto;
    background-color: transparent;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.375rem;
    border-radius: 0;
    border: none;
    outline: none;
    cursor: pointer;
  }

  /deep/.el-radio > .el-radio__input > .el-radio__inner {
    border-radius: 100%;
    width: 1.125rem;
    height: 1.125rem;
    background: #545454 !important;
    border: none;
  }
  /deep/.el-radio__input.is-checked .el-radio__inner::after {
    width: 8px;
    height: 8px;
    background: #f3cdb6;
  }

  /deep/.el-radio__label {
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #d8d8d8;
    line-height: 20px;
  }

  /deep/.el-textarea__inner {
    margin-top: 16px;
    width: 90%;
    height: 96px;
    background: #4a4a4a;
    border-radius: 4px;
    border: none;
    outline: none;
  }
  .van-button {
    width: 90%;
    height: 2.875rem;
    background-image: url('../assets/icon/confirm-button.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: 100% auto;
    background-color: transparent;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.375rem;
    border-radius: 0;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .composite {
    width: 94%;
    margin: 0.625rem 3%;
    background: #262728;
    border-radius: 8px;
    padding: 13px 0;
    .title {
      font-size: 16px;
      color: #ffffff;
      line-height: 22px;
      margin: 0 0 8px 21px;
    }
    .list {
      margin-left: 21px;
      display: flex;

      .list-detail-name {
        font-size: 14px;
        font-family: Noto Sans S Chinese;
        font-weight: 400;
        color: #fefefe;
      }
    }
    .expand {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 12px;
        color: #666666;
      }
      img {
        width: 14px;
        height: 9px;
        margin-left: 6px;
      }
    }
  }
  /deep/.v-modal {
    z-index: 2002 !important;
  }
}
.shipped-img {
  width: 1.5rem;
  height: auto;
  margin-right: 0.5625rem;
}
.coupon-dialog {
  height: auto !important;
  margin-top: 20vh !important;
  /deep/.el-dialog {
    height: 15rem !important;
  }
}
</style>